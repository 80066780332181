export default {
  header: 'Login',
  subheader: 'Dometic Vehicle Outfitters staff only',
  emailLabel: 'Your email address',
  emailRequiredLabel: 'Email address is required',
  passwordLabel: 'Your password',
  passwordRequiredLabel: 'Password is required',
  buttonLabel: 'Login',
  loginWithMicrosoftButtonLabel: 'Login with Microsoft'
}
