export default {
  header: 'Anmelden',
  subheader: 'Nur für Dometic Vehicle Outfitters Mitarbeiter',
  emailLabel: 'Ihre E-Mail-Adresse',
  emailRequiredLabel: 'E-Mail-Adresse ist erforderlich',
  passwordLabel: 'Ihr Passwort',
  passwordRequiredLabel: 'Passwort ist erforderlich',
  buttonLabel: 'Anmelden',
  loginWithMicrosoftButtonLabel: 'Mit Microsoft anmelden'
}
